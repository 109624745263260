<template>
    <div class="page-wrapper">
        <section class="hero_centre">
            <div class="title_wrap" data-inview="fadeInUp" data-delay="200">
                <img src="@/assets/img/icon-maison.svg" alt="Icon Maison Péladeau" />
                <h1 class="title"><span v-html="contentIsLoaded ? content.titre : ''"></span></h1>
                <h2 class="title small"><span v-html="contentIsLoaded ? content.titre1 : ''"></span></h2>
            </div>
            <div class="contacts">
                <a
                    data-inview="fadeInUp"
                    data-delay="200"
                    :href="'https://www.google.com/maps/place/' + (contentIsLoaded ? content.informationsDeContact.adresse.replace('<br />', ' ') : '')"
                    target="_blank"
                    class="box"
                >
                    <img src="@/assets/img/icon-map.svg" alt="Icon map" />
                    <address class="regular-text">
                        <span v-html="contentIsLoaded ? content.informationsDeContact.adresse : ''"></span>
                    </address>
                </a>
                <a data-inview="fadeInUp" data-delay="300" :href="'tel:' + (contentIsLoaded ? content.informationsDeContact.telephone : '')" class="box">
                    <img src="@/assets/img/icon-phone.svg" alt="Icon phone" />
                    <p class="regular-text"><span v-html="contentIsLoaded ? content.informationsDeContact.telephone : ''"></span></p>
                </a>
                <a
                    data-inview="fadeInUp"
                    data-delay="400"
                    :href="'mailto:' + (contentIsLoaded ? content.informationsDeContact.courriel : '')"
                    class="box"
                >
                    <img src="@/assets/img/icon-mail.svg" alt="Icon mail" />
                    <p class="regular-text"><span v-html="contentIsLoaded ? content.informationsDeContact.courriel : ''"></span></p>
                </a>
            </div>
        </section>

        <section class="carrousel_photo" v-if="pageIsLoaded">
            <div class="main-carousel-2">
                <div class="carousel-cell" v-for="(item, i) in content.images" :key="i">
                    <img
                        :src="item.url"
                        :alt="item.titre"
                    />
                </div>
            </div>
        </section>

        <section
            class="text_img" :class="{ 'switch': i % 2 }"
            v-for="(item, i) in splitImageTexte"
            :key="i"
        >
            <div class="data">
                <h3 class="title medium" data-inview="fadeInUp" data-delay="200">
                    {{ item.titre }}
                </h3>
                <p class="regular-text" data-inview="fadeInUp" data-delay="300">
                    <span v-html="item.texte"></span>
                </p>
                <a
                    :href="item.boutonUrl[$route.meta.lang]"
                    target="_blank"
                    class="cta regular-text"
                    data-inview="fadeInUp"
                    data-delay="400"
                    >{{ item.bouton.customText }}</a
                >
            </div>
            <div class="img" :data-inview="i % 2 ? 'slideRight' : 'slideLeft'" data-delay="200">
                <img :src="item.image.url" :alt="item.image.titre" />
            </div>
            <img src="@/assets/img/texture.svg" alt="texture" class="texture" />
        </section>

        <section class="testimonials" data-inview="fadeInUp" data-delay="200" v-if="pageIsLoaded">
            <img src="@/assets/img/quote.svg" alt="quote" class="quote" data-inview="fadeInUp" data-delay="300" />
            <div class="carousel-wrap">
                <img src="@/assets/img/texture-2.svg" alt="texture" class="texture" />
                <div class="main-carousel" data-inview="fadeInUp" data-delay="600">
                    <div class="carousel-cell" v-for="(item, i) in content.temoignages" :key="i">
                        <div class="left">
                            <img
                                :src="item.image.url"
                                data-inview="slideRight"
                                data-delay="200"
                                :alt="item.image.titre"
                            />
                        </div>
                        <div class="right">
                            <h4 class="title small">
                                <span v-html="item.titre"></span>
                            </h4>
                            <p class="regular-text">
                                <span v-html="item.texte"></span>
                            </p>
                            <p class="title name">
                                <span v-html="item.sousTitre"></span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="team_wrap" v-if="pageIsLoaded && content.equipe1.length">
            <div class="title_wrap">
                <h3 class="title medium" data-inview="fadeInUp" data-delay="200">
                    {{ content.entete1.titre }}
                </h3>
                <p class="regular-text" data-inview="fadeInUp" data-delay="300">
                    <span v-html="content.entete1.texte"></span>
                </p>
            </div>
            <div class="photos">
                <div class="photo" data-inview="fadeInUp" data-delay="200" v-for="(item, i) in content.equipe1" :key="i">
                    <div class="img"><img :src="item.image.url" :alt="item.image.titre" /></div>
                    <p class="regular-text name">{{ item.titre }}</p>
                    <p class="regular-text">{{ item.texte }}</p>
                </div>
            </div>
        </section>

        <section class="team_wrap" v-if="pageIsLoaded && content.equipe2.length">
            <div class="title_wrap">
                <h3 class="title medium" data-inview="fadeInUp" data-delay="200">
                    {{ content.entete2.titre }}
                </h3>
                <p class="regular-text" data-inview="fadeInUp" data-delay="300">
                    <span v-html="content.entete2.texte"></span>
                </p>
            </div>
            <div class="photos">
                <div class="photo" data-inview="fadeInUp" data-delay="200" v-for="(item, i) in content.equipe2" :key="i">
                    <div class="img"><img :src="item.image.url" :alt="item.image.titre" /></div>
                    <p class="regular-text name">{{ item.titre }}</p>
                    <p class="regular-text">{{ item.texte }}</p>
                </div>
            </div>
        </section>

        <section class="team_wrap" v-if="pageIsLoaded && content.equipe3.length">
            <div class="title_wrap">
                <h3 class="title medium" data-inview="fadeInUp" data-delay="200">
                    {{ content.entete3.titre }}
                </h3>
                <p class="regular-text" data-inview="fadeInUp" data-delay="300">
                    <span v-html="content.entete3.texte"></span>
                </p>
            </div>
            <div class="photos">
                <div class="photo" data-inview="fadeInUp" data-delay="200" v-for="(item, i) in content.equipe3" :key="i">
                    <div class="img"><img :src="item.image.url" :alt="item.image.titre" /></div>
                    <p class="regular-text name">{{ item.titre }}</p>
                    <p class="regular-text">{{ item.texte }}</p>
                </div>
            </div>
        </section>

        <section class="team_wrap" v-if="pageIsLoaded && content.equipe4.length">
            <div class="title_wrap">
                <h3 class="title medium" data-inview="fadeInUp" data-delay="200">
                    {{ content.entete4.titre }}
                </h3>
                <p class="regular-text" data-inview="fadeInUp" data-delay="300">
                    <span v-html="content.entete4.texte"></span>
                </p>
            </div>
            <div class="photos">
                <div class="photo" data-inview="fadeInUp" data-delay="200" v-for="(item, i) in content.equipe4" :key="i">
                    <div class="img"><img :src="item.image.url" :alt="item.image.titre" /></div>
                    <p class="regular-text name">{{ item.titre }}</p>
                    <p class="regular-text">{{ item.texte }}</p>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
// @ is an alias to /src
import { mapState, mapGetters } from 'vuex'
import Flickity from 'flickity'

export default {
    name: 'Centre',

    data() {
        return {
            teamCarousel: null,
        }
    },

    methods: {
        toogleNavBarStatus() {
            const st = document.documentElement.scrollTop

            if (!document.querySelector('.hero_centre')) {
                return
            }

            if (st >= 75) {
                document.querySelector('.hero_centre').classList.add('remove_margin_top')
            } else {
                document.querySelector('.hero_centre').classList.remove('remove_margin_top')
            }
        },
    },

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                    setTimeout(() => {
                        this.teamCarousel = new Flickity(document.querySelector('.main-carousel'), {
                            wrapAround: true,
                            imagesLoaded: true,
                            prevNextButtons: true,
                            pageDots: false,
                        })
                        this.teamCarousel = new Flickity(
                            document.querySelector('.main-carousel-2'),
                            {
                                wrapAround: true,
                                imagesLoaded: true,
                                prevNextButtons: true,
                                pageDots: false,
                            }
                        )
                        this.toogleNavBarStatus()
                        window.addEventListener('scroll', () => {
                            this.toogleNavBarStatus()
                        })
                    }, 1055)
                }
            },
            immediate: true,
        },
    },

    computed: {
        splitImageTexte() {
            if (!this.contentIsLoaded) {
                return []
            }
            return this.content.splitImageTexte
        },
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },
}
</script>
